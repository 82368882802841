/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.css';
@import "~ng-pick-datetime/assets/style/picker.min.css";

/* @primary-color: red; */

.ng-invalid:not(form){
    border-left:5px solid red;
}
.ng-valid:not(form){
    border-left:5px solid green;
}

.header-color{
    background-color: rgba(0,92,111,0.75);
    color: beige;
}

/*Tables Styles*/

.no-data-available {
    text-align: center;
  }

.dataTables_empty {
    display: none;
}

table{
    font-size: 12px;
}
hr{
    border-bottom: #ccc 1px solid;
}
.uppercase{
    text-transform: uppercase;
}

